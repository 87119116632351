import {PropsWithChildren, ReactComponentElement, useEffect, useMemo, useState} from "react";
import {Head, Link, router} from "@inertiajs/react";
import hero from "@/components/images/hero-1440x960.jpg";
import ApplicationLogo from "@/components/ApplicationLogo";
import Login from "@/components/Auth/Login";
import {User} from "@/types/User";
import {AnimatePresence, motion} from "framer-motion";
import {Button} from "@mui/material";

export default function WelcomeLayout({ auth, showSignup, key, nav, children }: PropsWithChildren<{auth: { user: User }, showSignup?: () => void, key?: string | number, nav?: ReactComponentElement<any>}> ) {
    return (
        <>
            <Head title="Welcome" />
            <div className="min-h-screen bg-cover overflow-hidden" style={{
                backgroundImage: `url(${hero}`,
            }}>
                <nav>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="shrink-0 flex items-center">
                                    <Link href="/">
                                        <ApplicationLogo className="float-left h-9 w-auto fill-current"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="hidden sm:flex sm:items-center sm:ms-6">
                                {auth.user ? (
                                    <Link
                                        href={route('dashboard')}
                                        className="font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"
                                    >
                                        Dashboard
                                    </Link>
                                ) : (
                                    <>
                                        {/*<Link*/}
                                        {/*    href={route('login')}*/}
                                        {/*    className="font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"*/}
                                        {/*>*/}
                                        {/*    Log in*/}
                                        {/*</Link>*/}

                                        { showSignup ?
                                            <button
                                                className="mt-3 bg-bonza-lilac text-bonza-semi-dark px-5 py-2 rounded-3xl"
                                                onClick={showSignup}
                                            >
                                            Sign up
                                            </button>
                                        : null }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
                    {children}
                </main>
            </div>
        </>
    );
}
