import { Link, Head } from '@inertiajs/react';
import { PageProps } from '@/types';
import Login from "@/components/Auth/Login";
import WelcomeLayout from '@/layouts/WelcomeLayout';
import SignUp from "@/pages/Modals/SignUp";
import {useState} from "react";

export default function Welcome({ auth, status, canRegister, canResetPassword, laravelVersion, phpVersion }: PageProps<{ status: string, canRegister: boolean, canResetPassword: boolean, laravelVersion: string, phpVersion: string }>) {
    const [showingSignup, setShowingSignup] = useState(false);

    const handleClose = () => setShowingSignup(false);

    return (
        <WelcomeLayout auth={auth} showSignup={() => {setShowingSignup(true)}}>
            <div className="relative flex flex-row h-[calc(100vh-64px)] px-2 mx-4 overflow-auto" style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 items-center text-3xl text-white flex-9">
                        <div className="text-center">
                            <h2 className="mt-8 text-6xl text-gray-900 dark:text-white"
                                >
                                The power and joy of making music
                            </h2>

                            <h4 className=" text-gray-900 dark:text-white text-3xl leading-relaxed mt-4">
                                Together. Wherever. Whenever
                            </h4>
                        </div>
                </div>
                <div
                    className="max-w-3xl mx-auto sm:px-6 lg:px-16 items-center bg-white text-black md:ml-8 p-8 rounded-3xl flex flex-col">
                    <div className="flex flex-row align-middle mb-5">
                        <button className="w-40 py-3" style={{borderBottom: '1px solid #cdaaf8'}}>Log in</button>
                        <button className="w-40 py-3" style={{borderBottom: '1px solid #e5e9ec'}} onClick={() => setShowingSignup(true)}>Sign up</button>
                    </div>
                    <div className="w-80 pt-6 mb-6">
                        <Login status={status} canResetPassword={canResetPassword}/>
                    </div>
                    <div className="w-80 mb-4" style={{borderBottom: '1px solid #e5e9ec'}}>&nbsp;</div>
                    <div className="w-80 text-center pt-6">Got a passcode? <Link
                        className="text-bonza-lilac underline" href="#">Enter here</Link></div>
                </div>
            </div>
            { showingSignup ? <SignUp handleClose={handleClose}/> : null }
        </WelcomeLayout>
    );
}
