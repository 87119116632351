import {useEffect, FormEventHandler, useState} from 'react';
import Checkbox from '@/components/Checkbox';
import GuestLayout from '@/layouts/GuestLayout';
import InputError from '@/components/InputError';
import InputLabel from '@/components/InputLabel';
import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import { Head, Link, useForm } from '@inertiajs/react';
import {VisibilityOffOutlined} from "@mui/icons-material";
import {VisibilityOutlined} from "@mui/icons-material";
import {createTheme, ThemeProvider} from "@mui/material";
import {Switch} from "@/components/parts/Switch";
import LoginTextInput from '../LoginTextInput';

export default function Login({ status, canResetPassword }: { status?: string, canResetPassword: boolean }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#cdaaf8',
                dark: '#cdaaf8',
            },
        },
        components: {
            MuiSwitch: {

            }
        }
    });

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        post(route('login'));
    };

    return (
        <ThemeProvider theme={theme}>

            {status && <div className="mb-4 font-medium text-sm text-green-600 dark:text-green-400">{status}</div>}

            <form onSubmit={submit}>
                <div>
                    <LoginTextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full rounded"
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData('email', e.target.value)}
                        placeholder="Email or username"
                    />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4 relative">
                    <LoginTextInput
                        id="password"
                        type={showPassword ? "text": "password"}
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        placeholder="Password"
                        onChange={(e) => setData('password', e.target.value)}
                    />
                    <div className="absolute text-gray-400 cursor-pointer"
                                           onClick={() => setShowPassword(!showPassword)}
                                           style={{
                        transform:'scale(-1, 1)',
                        right: '1rem',
                        top: '20%'
                                           }}>
                        {showPassword ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}
                    </div>

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="block my-6">
                    <label className="flex items-center justify-center">
                        <Switch
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData('remember', e.target.checked)}
                            className="scale-75"
                        />
                        <span className="ms-2 text-sm text-gray-800 dark:text-gray-800">Remember me</span>
                    </label>
                </div>

                <div className="flex items-center justify-end mt-4 flex-col">
                    <PrimaryButton className="rounded py-2 w-full" disabled={processing}>
                        Log in
                    </PrimaryButton>
                    {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className="underline text-xs mt-4 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Forgotten your password?
                        </Link>
                    )}

                </div>
            </form>
        </ThemeProvider>
    );
}
